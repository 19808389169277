.footer {
  position: absolute;
  bottom: 0;
  left: 0;

  padding-top: 0.25rem;
  background-color: var(--primary-color);
  width: 100%;
  opacity: 0.9;
  color: white;
  text-align: center;
  height: 2rem;

  .bee-link {
    margin: 1rem 2rem 0 2rem;
    color: var(--secondary-dark-color);
    font-size: small;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: var(--secondary-color);
    }

    &:active {
      color: var(--secondary-light-color);
    }
  }
}
