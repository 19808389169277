.bee-date-input-container {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bee-date-input-container .p-calendar {
  width: 100%;
}

.bee-date-input-label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 2.5rem - 6px);
  max-width: -moz-calc(100% - 2.5rem - 6px);
  max-width: calc(100% - 2.5rem - 6px);
  padding-left: 4px;
  padding-right: 6px;
}

.bee-date-input-label-required {
  padding-right: 3px;
  padding-left: 4px;
}

.bee-date-input-label .bee-date-input-label-required-star {
  position: absolute;
  right: 0px;
  max-width: 100%;
  padding-right: inherit;
}

.bee-date-input-container .p-calendar-disabled + label {
  padding-right: 4px;
  max-width: -webkit-calc(100% - 45px);
}

.bee-date-input .p-inputtext {
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bee-date-input-valid {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-date-input-error {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-date-input-readOnly {
  border-style: none !important;
  padding: 1px;
}

.bee-date-input-readOnly + label {
  max-width: -webkit-calc(100% - 17px) !important;
}

.bee-date-input-readOnly .p-inputtext {
  opacity: 1 !important;
}

.bee-date-input-readOnly button {
  display: none;
}
