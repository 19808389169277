.dl-tab-property {
  h1 {
    width: 100%;
  }
  .dl-tab-property-analytics {
    padding-left: 3rem;
    margin-top: auto;
    .dl-overview-analytics-chart {
      padding: 0rem !important ;
      margin: 0px !important;
      .p-chart {
        padding-right: 0.5rem;
        margin-top: 0.25rem;
        max-width: 510px;
        margin-left: auto;
        height: 21.5rem;
      }
    }
  }

  .dl-tab-property-lv {
    padding-top: 3em;
  }
}
