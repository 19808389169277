.alpha-analysis-ls.p-card {
  width: 100%;
  .p-card-body {
    padding: 0px;
    overflow: hidden;
  }
}

.alpha-analysis-ls.p-card .p-card-content {
  max-width: 100%;
  padding: 0px;
}

/* Cardview */

.alpha-analysis-ls-card.p-card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 8px 27px 3px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 4px;
  padding-bottom: 10px;
  margin: 0px;
  position: relative;
}

.alpha-analysis-ls-card-headline {
  padding: 1em 0.75rem 0.5em 1.5rem;
  background-color: var(--secondary-dark-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  h2 {
    color: var(--primary-color);
    font-weight: 500;
  }
}

.alpha-analysis-ls-card-headline div {
  font-size: 0.7em;
  color: var(--primary-color-text);
  font-weight: 800;
  line-height: 1.1em;
  height: 1.5em;
  width: calc(100% - 40px);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  display: block;
  word-break: break-all;
}

.alpha-analysis-ls-card-headline h2 {
  font-size: 1em;
  line-height: 1.2em;
  height: 2.5em;
  margin: 0em;
  margin-bottom: 0.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  width: calc(100% - 40px);
  padding: 0rem;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.a-analysis-liegenschaft-prev-card-table {
  min-height: 5em;
  width: 100%;
  font-size: 0.9em;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  table-layout: fixed;
  .card-table-ranking-icon {
    width: 1.5em;

    .icon-prize {
      &:before {
        color: #080891;
      }
      &.gold:before {
        color: #f8c034;
      }
      &.silver:before {
        color: #9d9d9d;
      }
      &.bronze:before {
        color: #a52a2a;
      }
    }
  }
  .card-table-organisation {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
  }
  .card-table-price {
    text-align: right;
    padding-right: 0rem;
    width: 40%;
    div {
      display: flex;
      align-items: center;
      input {
        border: none;
        font-size: 0.9em;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0.15rem;
        padding-right: 2em;
        text-align: end;
        background: transparent !important;
      }
      i {
        font-size: 0.9em;
      }
      .card-table-kiloPrice {
        position: relative;
        .card-table-price-kilo-tag {
          position: absolute;
          font-size: 1.2em;
          color: var(--surface-600);
          top: 50%;
          margin-top: -0.76em;
          right: 1.65rem;
        }
      }
    }
  }
  .card-table-incomplete {
    width: 1.25em;

    svg {
      width: 1.25em;
      margin-left: -0.25em;
      margin-bottom: -0.1em;
    }
  }
}

.lsCard-img {
  position: relative;
  .p-chip {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    background-color: var(--primary-color);

    .p-chip-icon {
      margin-right: 0;
      color: var(--surface-600) !important;
      padding: 0.25rem 0rem;
      font-size: 1.4em;
    }
    &.active {
      .p-chip-icon {
        color: var(--primary-color-text) !important;
      }
    }
  }
}

.alpha-analysis-ls-card-img {
  .bee-img {
    height: 180px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  .p-skeleton {
    overflow: hidden;
    padding-bottom: 10px;
    width: inherit !important;
    height: inherit !important;
    border-radius: 0px;
  }
}

.alpha-analysis-ls-card-info {
  position: absolute;
  right: 0px;
  margin-top: 0.75em;
  margin-right: 0.75em;

  button.p-button {
    height: 1.75rem !important;
    width: 1.85rem;
    border: 1px solid !important;
    background: var(--secondary-dark-color);

    &:hover {
      background-color: var(--secondary-color-68) !important;
    }
  }
  .p-button-icon {
    font-size: 1rem;
  }
}

/* Hover deactivated */
.alpha-analysis-ls-card.p-card.alpha-liegenschaft-ls-noHover {
  cursor: auto;
}

/* Hover activated */

.alpha-analysis-ls-card.p-card.alpha-liegenschaft-ls-hover:hover {
  transform: scale(1.02) translateZ(0);
  -ms-transform: scale(1.02) translateZ(0);
  -webkit-transform: scale(1.02) translateZ(0);
  transition: all 0.07s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.alpha-analysis-ls-card.p-card.alpha-liegenschaft-ls-hover:active {
  filter: brightness(0.9);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
