.bee-confirm-dialog.p-dialog {
  width: 40vw;
  max-width: 30rem;
}

.bee-confirm-dialog.p-dialog .p-confirm-dialog-message {
  margin-right: 1rem;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

/* primary */

.bee-confirm-dialog-primary.p-dialog .p-dialog-header,
.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-reject,
.bee-confirm-dialog-primary.p-dialog
  .p-dialog-header-icons
  .p-dialog-header-icon,
.bee-confirm-dialog-primary.p-dialog .p-dialog-content,
.bee-confirm-dialog-primary.p-dialog .p-dialog-footer {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-accept.p-button {
  background: var(--surface-a);
  color: var(--primary-color);
}

.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-accept.p-button:hover {
  background: var(--surface-300);
  color: var(--primary-color);
}

.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-accept.p-button:active {
  background: var(--surface-400);
  color: var(--primary-color);
}

.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-accept.p-button:focus {
  background: var(--surface-200);
  color: var(--primary-color);
}

.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-reject.p-button:hover,
.bee-confirm-dialog-primary.p-dialog
  .p-dialog-header
  .p-dialog-header-icon:enabled:hover {
  background: var(--primary-light-color);
  color: var(--primary-color-text);
}

.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-reject.p-button:active,
.bee-confirm-dialog-primary.p-dialog .p-dialog-header-icon:active {
  background: var(--primary-light-color);
  color: var(--primary-color-text);
}

.bee-confirm-dialog-primary.p-dialog .p-confirm-dialog-reject.p-button:focus,
.bee-confirm-dialog-primary.p-dialog .p-dialog-header-icon:focus {
  background: var(--primary-light-color);
  color: var(--primary-color-text);
}

/* secondary */

.bee-confirm-dialog-secondary.p-dialog .p-dialog-header,
.bee-confirm-dialog-secondary.p-dialog .p-confirm-dialog-reject,
.bee-confirm-dialog-secondary.p-dialog
  .p-dialog-header-icons
  .p-dialog-header-icon,
.bee-confirm-dialog-secondary.p-dialog .p-dialog-content,
.bee-confirm-dialog-secondary.p-dialog .p-dialog-footer {
  background: var(--secondary-color);
  color: var(--secondary-color-text);
}

.bee-confirm-dialog-secondary.p-dialog .p-confirm-dialog-accept.p-button {
  background: var(--surface-a) !important;
  color: var(--secondary-color) !important;
}

.bee-confirm-dialog-secondary.p-dialog .p-confirm-dialog-accept.p-button:hover {
  background: var(--surface-300) !important;
  color: var(--secondary-color) !important;
}

.bee-confirm-dialog-secondary.p-dialog
  .p-confirm-dialog-accept.p-button:active {
  background: var(--surface-400) !important;
  color: var(--secondary-color) !important;
}

.bee-confirm-dialog-secondary.p-dialog .p-confirm-dialog-accept.p-button:focus {
  background: var(--surface-200) !important;
  color: var(--secondary-color) !important;
}

.bee-confirm-dialog-secondary.p-dialog .p-confirm-dialog-reject:hover,
.bee-confirm-dialog-secondary.p-dialog .p-dialog-header-icon:hover {
  background: var(--secondary-light-color) !important;
  color: var(--secondary-color-text) !important;
}

.bee-confirm-dialog-secondary.p-dialog .p-confirm-dialog-reject:active,
.bee-confirm-dialog-secondary.p-dialog .p-dialog-header-icon:active {
  background: var(--secondary-light-color) !important;
  color: var(--secondary-color-text) !important;
}

.bee-confirm-dialog-secondary.p-dialog .p-confirm-dialog-reject:focus,
.bee-confirm-dialog-secondary.p-dialog .p-dialog-header-icon:focus {
  background: var(--secondary-light-color) !important;
  color: var(--secondary-color-text) !important;
}

/* default */

.bee-confirm-dialog-default.p-dialog .p-confirm-dialog-accept.p-button:hover {
  background: var(--primary-light-color);
}

.bee-confirm-dialog-default.p-dialog .p-confirm-dialog-reject:hover,
.bee-confirm-dialog-default.p-dialog .p-dialog-header-icon:hover {
  background: var(--primary-color-4) !important;
}

.bee-confirm-dialog-default.p-dialog .p-confirm-dialog-reject:active,
.bee-confirm-dialog-default.p-dialog .p-dialog-header-icon:active {
  background: var(--primary-light-active-color) !important;
}

.bee-confirm-dialog-default.p-dialog .p-confirm-dialog-reject:focus,
.bee-confirm-dialog-default.p-dialog .p-dialog-header-icon:focus {
  background: var(--primary-color-12) !important;
}

/* responsiveness */

@media (max-width: 500px) {
  .bee-confirm-dialog.p-dialog {
    width: 80vw;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .bee-confirm-dialog.p-dialog {
    width: 60vw;
  }
}
