.dl-overview-tab-view {
  .tabview-spinner {
    .p-progress-spinner {
      height: 5em !important;
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
      float: unset;
      width: 100% !important;
      overflow: hidden;
    }
  }
}
