.dl-priceInput {
  margin-top: 4.5rem;

  .priceInput-headline {
    h2 {
      text-align: left;
    }
  }
  .priceInput-descr {
    margin-bottom: 1.75rem;
  }

  /* filter */
  .filter-area {
    align-items: center;

    /* dropdown */
    .filter {
      .p-dropdown .p-inputtext {
        margin: 0.14rem !important;
      }
    }
    .liegenschaften-select {
    }

    /* switch */
    .bee-switch-input {
      padding: 0.79rem;
    }

    /* completion chip */
    .input-completion {
      text-align: right;
    }
  }

  /* table */
  .ep-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-treetable-wrapper {
      border-radius: 4px;
      overflow: hidden;

      /* allg */
      .p-treetable-thead th {
        white-space: nowrap;
      }
      .p-treetable-thead th,
      .p-treetable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-treetable-tbody td {
          padding: 0.25rem 0.75rem !important;
        }

        .p-treetable-emptymessage td {
          padding: 0.5rem 0.75rem !important;
        }
      }

      /* head */
      th {
        background: var(--primary-color);
        color: var(--primary-color-text);
        .ep-table-title {
          display: flex;
          align-items: center;
          div {
            padding-right: 1rem;
          }
          .p-button-icon-only {
            .icon-closeAll,
            .icon-openAll {
              &::before {
                font-weight: 700;
              }
            }
          }
        }
        .priceInput-input-col {
        }
      }

      /* titlecol */

      .ep-position-col {
        .priceInput-ep-code {
          font-size: 0.9em;
          color: var(--surface-500);
        }
      }
      tbody tr {
        .ep-position-col {
          display: flex;
          align-items: baseline;
        }
      }

      /* unit */

      .priceInput-unit {
        padding-top: 1.3em;
      }

      /* input */

      .rahmenpreis-viewMode {
        align-items: center;
        position: relative;
        padding-right: 1.6rem;
        text-align: right;

        .col {
          padding: 0px;
        }

        .bee-cur-input-container {
          margin: 0px;
          input {
            border: none;
            text-align: right;
            padding-right: 2em;
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }
        i {
          right: 0em;
        }
      }
      .priceInput-table-postText {
        font-size: 0.9em;
        color: var(--surface-500);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .status-priceChange {
        position: absolute;
        top: 0.7rem;
        right: 0rem;
        /* status = progress */
        &.pending {
          .p-progress-spinner {
            width: 1em;
            height: 0.9em;
            margin: 0.1em 0rem !important;
          }
        }

        /* status = error */
        &.error {
          color: var(--error-dark-color);
          i {
            margin: 0 0rem 0 0.5rem;
            color: var(--error-dark-color) !important;
          }
        }
      }
    }

    /* col widths */

    .ep-position-col {
      width: 100%;
    }
    .filter-tags-col {
      width: 2.5em;
    }
    .unit-col {
      width: 6em;
    }
    .rahmenpreis-col {
      width: 15em;
    }
    .contained-in-ls-col {
      width: 3em;
    }
  }

  /* row colors */
  tr {
    background: none !important;
  }
  tr.c-1 {
    position: relative;
    font-weight: 600;
  }
  .c-1:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: -1;
    background: var(--primary-light-color) !important;
  }
  tr.c-2 {
    position: relative;
    font-weight: 500;
  }
  .c-2:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.18;
    z-index: -1;
    background: var(--primary-light-color) !important;
  }

  tr.c-3 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      z-index: -1;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-4 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.13;
      z-index: -1;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-5 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      z-index: -1;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-6 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.08;
      z-index: -1;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-7 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.05;
      z-index: -1;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-8 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.03;
      z-index: -1;
      background: var(--primary-light-color) !important;
    }
  }

  tr.p {
  }

  .priceInput-table-actions {
    justify-content: flex-end;

    .template-btn {
      padding-top: 0px;
      padding-bottom: 0px;
      > div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
          font-size: 0.9em;
        }
        button {
          min-width: unset;
          padding-top: 0px;
          padding-bottom: 0px;
          padding-left: 0.25em;
          padding-right: 0.25em;
        }
      }
    }
  }
}
