.bee-headline {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  &.bee-headline-secondary {
    color: var(--secondary-dark-color);
  }

  &.bee-headline-primary {
    color: var(--primary-color);
  }
}
