.App {
  text-align: center;
  overflow-x: hidden;
  min-height: 100vh;
  min-width: 100%;
  position: relative;
}

.root {
  width: 100%;
  overflow: hidden;
}

body {
  width: 100%;
  hyphens: auto;
}
.readOnly {
  pointer-events: none;
}
.disabled {
  opacity: 0.6;
  pointer-events: none;
  filter: grayscale(100%);
}
.disabled-clickable {
  opacity: 0.6;
  filter: grayscale(100%);
}

/* tabview */
.p-tabview.p-component {
  .p-tabview-nav-container {
    font-size: 1.2em;

    i {
      margin-right: 0.15em;
    }
    //selected tab
    .p-tabview-selected {
      a {
        color: var(--secondary-dark-color) !important ;
      }
    }
  }
  .p-tabview-panels {
    background: var(--surface-100);

    .p-treeselect,
    .p-dropdown,
    .bee-search-bar .p-inputtext,
    .leistungsart-sum-table th,
    .leistungsart-sum-table td,
    .leistungsart-sum-table .p-inputtext,
    .p-dataview-content,
    .p-float-label label,
    .p-cascadeselect,
    .dl-property-factor th,
    .dl-property-factor td,
    .p-inputtext {
      background: var(--surface-100);
      /*         background-color: var(--surface-100) !important; */
    }
  }
}
/* table */
/* sticky col */
.frozen-col {
  position: sticky !important;
  left: 0px;
  z-index: 20 !important;
}
td.frozen-col {
  background: #e1e3e7 !important;
  border-color: #e1e3e7 !important;
}

/* spinner */
.bee-page-spinner {
  .p-progress-spinner {
    height: 5em !important;
    margin-top: 4rem !important;
    margin-bottom: 3rem !important;
    float: unset;
    width: 100% !important;
    overflow: hidden;
  }
}

.visibility-hidden {
  visibility: hidden;
}

/* colors */

.valid-background::before {
  content: '';
  background-color: var(--valid-color);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.14;
  z-index: 0;
}
.warning-background::before {
  content: '';
  background-color: var(--warning-color);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.14;
  z-index: 0;
}
.error-background::after {
  content: '';
  background-color: var(--error-dark-color);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.14;
  z-index: 0;
}
.grey-background::after {
  content: '';
  background-color: var(--surface-600);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.14;
  z-index: 0;
}

.error-color {
  color: var(--error-dark-color);
}

.valid-color {
  color: var(--valid-color);
}
.warning-color {
  color: var(--warning-color);
}
.primary-color {
  color: var(--primary-color);
}
.secondary-color {
  color: var(--seacondar-dark-color);
}

/* icons */
i:before {
  font-size: 120% !important;
}
