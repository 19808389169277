.bee-ta-container {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bee-ta-container .p-inputtextarea {
  width: 100%;
}

.bee-ta-label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 1.1rem);
  max-width: -moz-calc(100% - 1.1rem);
  max-width: calc(100% - 1.1rem);
  padding-left: 4px;
  padding-right: 6px;
}

.bee-ta-label-required {
  padding-right: 3px;
  padding-left: 4px;
}

.bee-ta-label .bee-ta-label-required-star {
  position: absolute;
  right: 0px;
  max-width: 100%;
  padding-right: inherit;
}

.bee-ta-input-valid {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-ta-input-error {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-ta-input-readOnly {
  border-style: none !important;
  padding: 0.81rem;
  opacity: 1 !important;
}
