.dl-bundeslandfaktoren {
  margin-top: 2.5rem;
  text-align: left;

  .bundesland-descr {
    margin-bottom: 1.75rem;
    margin-top: -0.5rem;

    .readOnly {
      color: var(--error-dark-color);

      i {
        margin-right: 0.25em;
      }
    }
  }

  /* datatable */
  .p-datatable-table {
    /* column ellipsis + borders */
    .p-datatable-tbody,
    .p-datatable-thead {
      tr {
        td,
        th {
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0.5em 0.75em;

          &:first-of-type {
            border-width: 0 1px 1px 0;
          }
        }
      }
    }

    /* header */
    .p-column-header-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    /* inputfields */
    .p-datatable-tbody {
      .bl-col:not(:first-of-type) {
        padding-left: 0.5rem;
      }

      .status-factorChange {
        /* status = progress */
        .p-progress-spinner {
          width: 1em !important;
          height: 0.9em !important;
          margin: 0 0.25rem !important;
          float: unset;
        }
        /* status = error */
        &.error {
          color: var(--error-dark-color);
          i {
            margin: 0 0.1rem 0 0.5rem;
            color: var(--error-dark-color) !important;
          }
        }
      }

      .load-factor.pending {
        .p-progress-spinner {
          width: 1em;
          height: 0.9em;
          margin: 0 0.25rem;
        }
      }
    }
    /* colwidths */

    .dl-bundeslandfaktoren-bl-col {
      width: 25%;
    }
    .p-editable-column {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .bl-col-body {
      display: inline-block;
      .bee-num-input-container {
        margin: 0px;
        input {
          padding: 0px !important;
          width: 2.8em;
        }
      }
    }
  }

  /* SVG Map */

  .dl-bl-map-col {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .svg-map {
      max-width: 20rem;

      .svgMap_BL {
        fill: var(--secondary-color);
        stroke: var(--primary-color-68);
        &.opacity_0 {
          fill-opacity: 0.05;
        }
        &.opacity_0-0 {
          fill-opacity: 0.05;
        }
        &.opacity_0-1 {
          fill-opacity: 0.1;
        }
        &.opacity_0-2 {
          fill-opacity: 0.2;
        }
        &.opacity_0-3 {
          fill-opacity: 0.3;
        }
        &.opacity_0-4 {
          fill-opacity: 0.4;
        }
        &.opacity_0-5 {
          fill-opacity: 0.5;
        }
        &.opacity_0-6 {
          fill-opacity: 0.6;
        }
        &.opacity_0-7 {
          fill-opacity: 0.7;
        }
        &.opacity_0-8 {
          fill-opacity: 0.8;
        }
        &.opacity_0-9 {
          fill-opacity: 0.9;
        }
        &.opacity_1-0 {
          fill-opacity: 1;
        }
      }
    }
  }
}
