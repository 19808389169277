/* primary */

.bee-slider-primary.p-slider .p-slider-range, .bee-slider-primary.p-slider .p-slider-handle {
    background: var(--primary-color);
}

.bee-slider-primary.p-slider .p-slider-handle:focus {
    box-shadow: 0 0 1px 10px var(--primary-color-16);
}

.bee-slider-primary.p-slider:not(.p-disabled) .p-slider-handle:hover {
    box-shadow: 0 0 1px 10px var(--primary-color-12);
    background: var(--primary-color);
    border-color: 0 none;
}

/* secondary */

.bee-slider-secondary.p-slider .p-slider-range, .bee-slider-secondary.p-slider .p-slider-handle {
    background: var(--secondary-dark-color);
}

.bee-slider-secondary.p-slider .p-slider-handle:focus {
    box-shadow: 0 0 1px 10px var(--secondary-color-16);
}

.bee-slider-secondary.p-slider:not(.p-disabled) .p-slider-handle:hover {
    box-shadow: 0 0 1px 10px var(--secondary-color-12);
    background: var(--secondary-dark-color);
    border-color: 0 none;
}