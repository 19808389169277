.bee-breadcrumb-primary .p-menuitem-text, .bee-breadcrumb-primary .p-breadcrumb-chevron, .bee-breadcrumb-primary .p-menuitem-icon {
    color: var(--primary-color) !important;
}

.bee-breadcrumb-secondary .p-menuitem-text, .bee-breadcrumb-secondary .p-breadcrumb-chevron, .bee-breadcrumb-secondary .p-menuitem-icon {
    color: var(--secondary-dark-color) !important;
}

.bee-breadcrumb-secondary span {
    color: var(--secondary-dark-color) !important;
}

.bee-breadcrumb-primary span {
    color: var(--primary-dark-color) !important;
}

.bee-breadcrumb-no-border nav {
    border: none;
}

.bee-breadcrumb {
    width: 100%;
}