.dl-priceInput-page {
  margin-top: 3rem;
  .priceInput-header {
    position: relative;

    h1 {
      padding-right: 10rem;
    }
    /* countdown */
    .phase-countdown {
      position: absolute;
      top: -0.5rem;
      right: 0;
      .countdown {
        display: flex;
        align-items: center;
        text-align: center;

        .countdownBlock {
          margin-right: 0.25rem;
          font-size: 0.9em;

          .countdownNumber {
            background-color: var(--primary-color-12);
            border-radius: 4px;
          }
          .countdownLabel {
          }
        }
      }
    }
  }

  .loader-page {
    .p-progress-spinner {
      display: block;
      width: 100% !important;
      height: 5em !important;
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
      overflow: hidden;
    }
  }

  .priceInput-fatal-error-msg {
    text-align: center;
    font-size: 1.2em;
    margin: 3em 0;

    h1 {
      display: inline-block;
    }
    i {
      font-size: 2em;
    }
  }
}
