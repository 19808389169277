.bee-add-entry-card .p-button-icon {
    font-size: 1.2em;
}

.bee-add-entry-card .bee-icn-btn-primary.p-button:not(a):not(.p-disabled):hover {
    background: var(--primary-color)
}

.bee-add-entry-card {
    width: 100%;
    max-height: 100%;
}

.bee-add-entry-card .p-button::after {
    content: "";
    display: block;
    padding-top: 100%;
}