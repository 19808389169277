.bee-info-valid-label, .bee-info-neutral-label {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.bee-info-neutral-label {
  color: var(--neutral-color);
}

.bee-info-valid-label {
  color: var(--valid-color);
}

.bee-info-label-small {
  font-size: small;
}

.bee-info-label-medium {
  font-size: medium;
}

.bee-info-label-large {
  font-size: x-large;
}