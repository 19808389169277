@charset "UTF-8";
:root {
  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: rgba(0, 0, 0, 0.04);
  --surface-d: rgba(0, 0, 0, 0.12);
  --surface-e: rgba(0, 0, 0, 0.18);
  --surface-f: #ffffff;
  --text-color: rgba(0, 0, 0, 0.87);
  --text-color-secondary: rgba(0, 0, 0, 0.6);
  --primary-color: #001739;
  --primary-dark-color: #00122e;
  --primary-light-color: #3e4c68;
  --primary-light-active-color: rgba(63, 81, 181, 0.16);
  /* shades */
  --primary-color-100: #001739;
  --primary-color-92: #001739eb;
  --primary-color-76: #001739c2;
  --primary-color-68: #001739ad;
  --primary-color-50: #00173980;
  --primary-color-32: #00173952;
  --primary-color-16: #00173929;
  --primary-color-12: #0017391f;
  --primary-color-4: #0017390a;
  --secondary-color: #00e1d2;
  --secondary-dark-color: #00b4a8;
  --secondary-light-color: #00ffef;
  /* shades */
  --secondary-color-100: #00e1d2;
  --secondary-color-92: #00e1d2eb;
  --secondary-color-76: #00e1d2c2;
  --secondary-color-68: #00e1d2ad;
  --secondary-color-50: #00e1d280;
  --secondary-color-32: #00e1d252;
  --secondary-color-20: #00e1d233;
  --secondary-color-16: #00e1d229;
  --secondary-color-12: #00e1d21f;
  --secondary-color-4: #00e1d20a;
  --tertiary-color: #18b7b7;
  --tertiary-dark-color: #139292;
  --tertiary-light-color: #1ddcdc;
  --error-color: #f40921;
  --error-dark-color: #b71c1c;
  --valid-color: #2ab06f;
  --valid-dark-color: #1b5e20;
  --warning-color: #7f6003;
  --info-color: #154970;
  --neutral-color: #757575;
  /* diagram colors */
  --diagram-color-0: #00e1d2;
  --diagram-color-1: #001739;
  --diagram-color-2: #00acf6;
  --diagram-color-3: #a3f693;
  --diagram-color-4: #f9f871;
  --diagram-color-5: #a72683;

  --primary-color-text: #ffffff;
  --secondary-color-text: #001739;
  --primary-border-color: rgba(0, 0, 0, 0.38);
  --label-background-color: #ffffff;
  --font-family: Poppins, Roboto, Helvetica Neue Light, Helvetica Neue,
    Helvetica, Arial, Lucida Grande, sans-serif;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
}

/* poppins-regular */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins'), local('Poppins-Regular'),
    url('../Fonts/poppins-v20-latin-regular.woff2') format('woff2'),
    url('../Fonts/poppins-v20-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-500 - latin-ext_latin */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('../Fonts/poppins-v20-latin-500.woff2') format('woff2'),
    url('../Fonts/poppins-v20-latin-500.woff2') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-700 - latin-ext_latin */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('../Fonts/poppins-v20-latin-700.woff2') format('woff2'),
    url('../Fonts/poppins-v20-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
