.main-page {
  overflow-x: hidden;
  max-width: calc(100vw);

  .main-page-content {
    padding: 0rem 2rem 5rem 5.5rem;
    min-height: calc(100vh - 8rem);
    height: 100%;
    text-align: left;
    /*     width: calc(100vw - 17px); */
  }
}
