.dl-property-lv {
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    filter: grayscale(100%);
  }
  .propertyLv-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-treetable-wrapper {
      border-radius: 4px;
      overflow: hidden;

      /* allg */
      .p-treetable-thead th {
        white-space: nowrap;
      }
      .p-treetable-thead th,
      .p-treetable-tbody td,
      .p-treetable-tfoot td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-treetable-tbody td,
        .p-treetable-tfoot td {
          padding-top: 0.25rem !important;
          padding-bottom: 0.25rem !important;
          input {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
          }
        }

        .p-treetable-emptymessage td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }

      /* head */
      th {
        background: var(--primary-color) !important;
        color: var(--primary-color-text);
        .propertyLv-title-col {
          display: flex;
          align-items: center;
          div {
            padding-right: 1rem;
          }
          .p-button-icon-only {
            .icon-closeAll,
            .icon-openAll {
              &::before {
                font-weight: 700;
              }
            }
          }
        }
      }

      tr {
        border-bottom: 1px solid #e4e4e4;
        td {
          border-bottom: none;
        }
      }
      /* footer */
      .p-treetable-tfoot {
        border-top: 1px solid var(--surface-400);
      }

      /* cols */

      tbody tr {
        .subtitle {
          font-size: 0.9em;
          color: var(--surface-600);
        }
        /* titlecol */
        .propertyLv-title-col {
          display: flex;
          align-items: baseline;

          .dl-property-lv-aks {
            white-space: nowrap;
          }
          .dl-property-lv-epCode {
            margin-right: 0.2em;
          }
        }
      }

      /* allg */
      table tr {
        .propertyLv-gp-col {
          padding-right: 2.15em !important;
          .bee-txt-input-container {
            display: inline-block;
          }
          svg {
            display: inline-block;
            margin-right: -1.25em;
            margin-bottom: -0.2em;
          }
        }
      }
      .propertyLv-gp-col,
      .propertyLv-ep-col,
      .propertyLv-fakEp-col {
        text-align: right;
      }
      .propertyLv-ep-col,
      .propertyLv-gp-col,
      .propertyLv-fakEp-col {
        input {
          border: none;
          background: transparent;
          text-align: right;
        }
      }
    }

    /* col widths */

    .propertyLv-title-col {
      width: 100%;
    }
    .propertyLv-icon-col {
      width: 3.5em;
    }
    .propertyLv-unit-col {
      width: 5em;
    }
    .propertyLv-type-col {
      width: 7em;
    }
    .propertyLv-amount-col {
      width: 5em;
    }
    .propertyLv-ep-col {
      width: 10em;
    }
    .propertyLv-blf-col {
      width: 4em;
    }
    .propertyLv-lsf-col {
      width: 4em;
    }
    .propertyLv-susf-col {
      width: 4em;
    }
    .propertyLv-fakEp-col {
      width: 10em;
    }
    .propertyLv-gp-col {
      width: 14em;
    }
    .sm-invisible {
    }
  }

  /* row colors */
  tr {
    background: none !important;
  }
  tr.lv-1 {
    font-weight: 600;
    background: var(--secondary-color-12) !important;
    input {
      font-weight: 600;
    }
  }

  tr.lv-2 {
    font-weight: 500;
    background: var(--primary-color-4) !important;
  }

  tr.lv-3 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.05;
      z-index: -1;

      background: var(--primary-light-color) !important;
    }
  }
  tr.lv-4 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.03;
      z-index: -1;

      background: var(--primary-light-color) !important;
    }
  }

  tr.p {
  }

  @media screen and (max-width: 70em) {
    .sm-invisible {
      display: none;
    }

    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }
}
