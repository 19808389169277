.alpha-analysis-epTable {
  /* table */

  .alpha-analysis-epTable-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-treetable-wrapper,
    .p-treetable-scrollable-wrapper {
      border-radius: 4px;
      overflow: hidden;
      .p-treetable-scrollable-header-box {
        margin-right: 0px !important;
      }
      /* allg */
      .p-treetable-thead th {
        white-space: nowrap;
      }
      .p-treetable-thead th,
      .p-treetable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */
        .p-treetable-tbody td {
          padding: 0.25rem 0.75rem !important;
        }

        .p-treetable-emptymessage td {
          padding: 0.5rem 0.75rem !important;
        }
      }

      /* head */
      .p-treetable-thead > tr > th {
        background: var(--primary-color);
        color: var(--primary-color-text);
        .ep-table-title {
          display: flex;
          align-items: center;
        }
      }
      .analysis-ep-table-dl-header {
        overflow: visible !important;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      /* titlecol */

      .ep-position-col {
        .alpha-analysis-ep-code {
          font-size: 0.9em;
          color: var(--surface-500);
        }
      }
      tbody tr {
        .ep-position-col {
          white-space: nowrap;
          text-overflow: clip;
          .p-treetable-toggler,
          .alpha-analysis-epTable-title {
            display: inline-block;
          }
        }
      }

      .alpha-epTable-epPrice-col,
      .alpha-epTable-diff-col {
        position: relative;

        input {
          background-color: transparent;
          text-align: right;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }

      /* coloring */
      .alpha-epTable-epPrice-col:has(
          + .alpha-epTable-diff-col > .valid-background
        ):after {
        content: '';
        background-color: var(--valid-color);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0.14;
        z-index: 0;
      }

      .alpha-epTable-epPrice-col:has(
          + .alpha-epTable-diff-col > .warning-background
        ):after {
        content: '';
        background-color: var(--warning-color);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0.14;
        z-index: 0;
      }

      .alpha-epTable-epPrice-col:has(
          + .alpha-epTable-diff-col > .error-background
        ):after {
        content: '';
        background-color: var(--error-dark-color);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0.14;
        z-index: 0;
      }
      .alpha-epTable-epPrice-col:has(
          + .alpha-epTable-diff-col > .grey-background
        ):after {
        content: '';
        background-color: var(--surface-600);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0.14;
        z-index: 0;
      }
    }
  }
}
