.alpha-phase-to-doc-table {
  .phase-to-doc-headline {
    h2 {
      margin-bottom: 0.25em;
    }
    h3 {
      margin-top: 0em;
    }
  }
}
