/* primary */

.bee-btn-primary.p-button:not(a):not(.p-disabled):hover {
  background: var(--primary-light-color);
}

/* secondary */

.bee-btn-secondary.p-button:not(a):not(.p-disabled):hover {
  background: var(--secondary-light-color);
}

/* danger */

.bee-btn-danger.p-button:not(a):not(.p-disabled):hover {
  background: var(--error-dark-color);
}

/* light */

.p-button.bee-btn-light {
  color: var(--primary-color);
  background-color: var(--surface-a);
}

.bee-btn-light.p-button:not(a):not(.p-disabled):hover {
  color: var(--primary-color);
  background-color: var(--primary-color-4);
}

.bee-btn-light.p-button:not(a):not(.p-disabled):active {
  color: var(--primary-color);
  background-color: var(--primary-light-active-color);
}

.bee-btn-light.p-button:not(a):not(.p-disabled):focus {
  background: var(--primary-color-12);
}
