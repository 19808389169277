/* primary */

.bee-icn-btn-primary {}

.bee-icn-btn-primary.p-button:not(a):not(.p-disabled):hover {
    background: var(--primary-light-color);
}

/* secondary */

.bee-icn-btn-secondary {}

.bee-icn-btn-secondary.p-button:not(a):not(.p-disabled):hover {
    background: var(--secondary-light-color);
}

/* danger */

.bee-icn-btn-danger {}

/* light */

.p-button.bee-icn-btn-light {
    color: var(--primary-color);
    background-color: var(--surface-a);
}

.bee-icn-btn-light.p-button:not(a):not(.p-disabled):hover {
    color: var(--primary-color);
    background-color: var(--primary-color-4);
}

.bee-icn-btn-light.p-button:not(a):not(.p-disabled):active {
    color: var(--primary-color);
    background-color: var(--primary-light-active-color);
}

.bee-icn-btn-light.p-button:not(a):not(.p-disabled):focus {
    background: var(--primary-color-12);
}