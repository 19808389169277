.alpha-vergabe-table {
  .alpha-vergabe-datatable {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-datatable-wrapper,
    .p-datatable-scrollable-wrapper {
      overflow-x: scroll;
      /* allg */
      .p-datatable-thead th {
        white-space: nowrap;
      }
      .p-datatable-thead th,
      .p-datatable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        border-radius: 4px;

        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-datatable-tbody td,
        .p-datatable-tfoot td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
          padding-left: 1em;
          padding-right: 1em;
          input {
            padding-top: 0em;
            padding-bottom: 0em;
          }
        }

        .p-datatable-emptymessage td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }

      /* head */
      .p-datatable-scrollable-header-box,
      .p-datatable-scrollable-footer-box {
        margin-right: 0px !important;
      }
      th {
        background: var(--primary-color) !important;
        color: var(--primary-color-text);
      }

      tr {
        border-bottom: 1px solid #e4e4e4;
        td {
          border-bottom: none;

          .p-inputtext {
            background: transparent;
          }
        }
      }

      .alpha-vergabe-dt-adress-col {
        &.frozen-col {
          left: 11em;
        }
      }

      tbody tr {
        position: relative;

        .alpha-vergabe-dt-prop-col {
          width: 30%;
          .lv-number {
            font-size: 0.9em;
            color: var(--surface-500);
          }
        }
        .alpha-vergabe-dt-adress-col {
          width: 20%;
        }
        .alpha-vergabe-dt-slice-col {
          width: 15%;
          font-size: 0.9em;
          position: relative;
          .col {
            padding: 0.25em;
          }
          /* organisation */
          .alpha-vergabe-organisation {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          /* chip */
          .p-chip {
            padding: 0 0.25rem;
            font-size: 0.95em;
            .p-chip-text {
              margin: 0px;
            }
          }

          /* suggestion */
          .alpha-vergabe-suggestion {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          /* price */
          .alpha-vergabe-kiloPrice {
            position: relative;
            .alpha-vergabe-price-kilo-tag {
              position: absolute;
              font-size: 1.1em;
              color: var(--surface-600);
              top: 50%;
              margin-top: -0.75em;
              right: 1.75rem;
            }
            .bee-cur-input-container {
              margin-top: 0px;
              margin-bottom: 0px;
              i {
                margin-top: -0.55rem;
                &:before {
                  font-size: 0.9em;
                }
              }
              input {
                padding-right: 2.8em;
                padding-left: 0em;
                font-size: 0.9em;
              }
            }
          }
          .alpha-vergabe-dt-vergeben {
          }
        }
      }
    }
  }
}
