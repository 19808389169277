.alpha-liegenschaft-eingabe-prev.p-card .p-card-body {
  padding: 0px;
  overflow: hidden;
}

.alpha-liegenschaft-eingabe-prev.p-card .p-card-content {
  max-width: 100%;
  padding: 0px;
}

/* Cardview */

.alpha-liegenschaft-eingabe-prev-card.p-card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 8px 27px 3px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 4px;
  padding-bottom: 10px;
  margin: 0px;
  position: relative;
}

.alpha-liegenschaft-eingabe-prev-card-headline {
  padding: 1em 0.75rem 0.5em 1.5rem;
  background-color: var(--secondary-dark-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  h2 {
    color: var(--primary-color);
    font-weight: 500;
  }
}

.alpha-liegenschaft-eingabe-prev-card-headline div {
  font-size: 0.7em;
  color: var(--primary-color-text);
  font-weight: 800;
  line-height: 1.1em;
  height: 1.5em;
  width: calc(100% - 40px);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  display: block;
  word-break: break-all;
}

.alpha-liegenschaft-eingabe-prev-card-headline h2 {
  font-size: 1em;
  line-height: 1.2em;
  height: 2.5em;
  margin: 0em;
  margin-bottom: 0.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  width: calc(100% - 40px);
  padding: 0rem;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.alpha-liegenschaft-eingabe-prev-card-table {
  font-size: 0.9em;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  tbody {
    display: block;

    tr {
      display: block;
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
  }
}

.alpha-liegenschaft-eingabe-prev-card-table .bee-badge-primary {
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  font-size: 0.8rem;
}

.alpha-liegenschaft-eingabe-prev-card-img > div {
  height: 180px;
  overflow: hidden;
}

.alpha-liegenschaft-eingabe-prev-card-img .p-skeleton {
  overflow: hidden;
  padding-bottom: 10px;
  width: inherit !important;
  height: inherit !important;
  border-radius: 0px;
}

.alpha-liegenschaft-eingabe-prev-card-img > div img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 50%;
}

/* Hover deactivated */

.alpha-liegenschaft-eingabe-prev-card.p-card.alpha-liegenschaft-eingabe-prev-noHover:active {
  transform: scale(0.98) translateZ(0);
  -ms-transform: scale(0.98) translateZ(0);
  -webkit-transform: scale(0.98) translateZ(0);
  filter: brightness(95%);
  transition: all 0.05s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

/* Hover activated */

.alpha-liegenschaft-eingabe-prev-card.p-card.alpha-liegenschaft-eingabe-prev-hover:hover {
  transform: scale(1.02) translateZ(0);
  -ms-transform: scale(1.02) translateZ(0);
  -webkit-transform: scale(1.02) translateZ(0);
  transition: all 0.07s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.alpha-liegenschaft-eingabe-prev-card.p-card.alpha-liegenschaft-eingabe-prev-hover:active {
  filter: brightness(0.9);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
