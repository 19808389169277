.alpha-dl-over-time-page {
  .large-spinner {
    margin: 5em 0;
    display: flex;
    justify-content: center;

    .bee-loading-spinner.p-progress-spinner {
      width: 6em !important;
    }
  }

  .alpha-dl-over-time-headline {
    .bee-dropdown-container {
      min-width: 15em;
    }
  }
}
