.bee-upload-dialog.p-dialog {
  width: 60vw;
  max-width: 700px;
}

.bee-upload-dialog .p-dialog-header {
  padding: 1rem;
}

.bee-upload-dialog .p-dialog-content {
  padding-top: 1rem;
  padding-bottom: 0.75rem;
}

.bee-upload-dialog .bee-error-label {
  padding-bottom: 0.75rem;
  display: block;
}

.bee-upload-dialog .p-dialog-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.75rem 0 1rem 0;
}

.bee-upload-dialog.p-dialog .p-dialog-footer {
  padding: 0.5rem 1.25rem 0.75rem 1.25rem;
}

/* dropzone */

.bee-upload-dialog .bee-upload-dialog-dropzone {
  background-color: var(--surface-50);
  color: var(--surface-400);
  text-align: center;
  border: 3px dashed;
}

.bee-upload-dialog .bee-upload-dialog-dropzone {
  padding: 1.5rem 1rem 2rem 1rem;
  margin: 0.5rem 0 0 0;
}

.bee-upload-dialog .bee-upload-dialog-dropzone .bee-content-paragraph,
.bee-upload-dialog .bee-upload-dialog-dropzone .bee-headline {
  text-align: center;
  color: var(--primary-color-32);
}

.bee-upload-dialog .bee-upload-dialog-dropzone .bee-headline {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.bee-upload-dialog .bee-upload-dialog-dropzone .bee-content-paragraph {
  margin: 0.5rem 0 1.25rem 0;
}

/* empty dropzone */

.bee-upload-dialog
  .bee-upload-dialog-dropzone
  .bee-upload-dialog-empty-drop
  .p-button {
  padding: 10px;
  width: 120px;
  font-size: 0.9rem;
  background-color: var(--primary-color-50);
  border-radius: 50%;
}

.bee-upload-dialog
  .bee-upload-dialog-dropzone
  .bee-upload-dialog-empty-drop
  .p-button:after {
  content: '';
  display: block;
  padding-top: 100%;
}

.bee-upload-dialog .bee-upload-dialog-empty-drop .bee-upload-dialog-formats {
  font-size: 0.8em;
  margin: 1.5rem 0 0.5rem 0;
  word-break: break-all;
  overflow: hidden;
}
.bee-upload-dialog .bee-upload-dialog-empty-drop .bee-upload-dialog-info {
  font-size: 0.8em;
  margin: 1.5rem 0 0.5rem 0;
  word-break: break-all;
  overflow: hidden;
}

/* error dropzone */

.bee-upload-dialog .bee-headline {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.bee-upload-dialog .bee-upload-dialog-error i {
  float: left;
  padding-left: 5vw;
  font-size: 5rem;
}

.bee-upload-dialog .bee-upload-dialog-error .grid {
  margin-top: 0px;
}

.bee-upload-dialog .bee-upload-dialog-error-descr {
  padding-right: 6vw;
  font-size: 0.8em;
}

/* selected dropzone */
.bee-upload-dialog .bee-upload-dialog-selected-drop {
  word-wrap: break-word;
}

.bee-upload-dialog .bee-upload-dialog-selected-drop i {
  display: block;
  font-size: 5rem;
  margin: 2rem 0 0.75rem 0;
}

.bee-upload-dialog .bee-upload-dialog-selected-drop button {
  background-color: var(--primary-color-50);
}

.bee-upload-dialog
  .bee-upload-dialog-selected-drop
  .bee-upload-dialog-file-info {
  margin-top: 2rem;
  font-size: 0.8em;
}

/* progress */

.bee-upload-dialog .bee-upload-dialog-progress {
  padding-top: 0.75rem;
}

.bee-upload-dialog .bee-upload-dialog-progress-msg {
  font-size: 0.8em;
  padding-top: 1rem;
}
.bee-upload-dialog .bee-upload-dialog-progress .bee-loading-spinner {
  width: 7rem !important;
  height: 7rem !important;
  float: unset;
}

/* primary */

.bee-upload-dialog-primary.p-dialog .p-dialog-header {
  background-color: var(--primary-color);
}

.bee-upload-dialog-primary.p-dialog .p-dialog-header .p-dialog-title,
.bee-upload-dialog-primary .p-dialog-header .p-dialog-header-icons button {
  color: var(--primary-color-text);
}

.bee-upload-dialog-primary.p-dialog .p-dialog-header-icon:hover {
  background: var(--primary-light-color) !important;
  color: var(--primary-color-text) !important;
}

.bee-upload-dialog-primary.p-dialog .p-dialog-header-icon:active {
  background: var(--primary-color-68) !important;
  color: var(--primary-color-text) !important;
}

.bee-upload-dialog-primary.p-dialog .p-dialog-header-icon:focus {
  background: var(--primary-color-76) !important;
  color: var(--primary-color-text) !important;
}

/* secondary */

.bee-upload-dialog-secondary.p-dialog .p-dialog-header {
  background-color: var(--secondary-color);
}

.bee-upload-dialog-secondary.p-dialog .p-dialog-header .p-dialog-title {
  color: var(--secondary-color-text);
}

.bee-upload-dialog-secondary.p-dialog
  .p-dialog-header
  .p-dialog-header-icons
  button {
  color: var(--secondary-color-text);
}

.bee-upload-dialog-secondary.p-dialog .p-dialog-header-icon:hover {
  background: var(--secondary-light-color) !important;
  color: var(--secondary-color-text) !important;
}

.bee-upload-dialog-secondary.p-dialog .p-dialog-header-icon:active {
  background: var(--secondary-color-68) !important;
  color: var(--secondary-color-text) !important;
}

.bee-upload-dialog-secondary.p-dialog .p-dialog-header-icon:focus {
  background: var(--secondary-color-76) !important;
  color: var(--secondary-color-text) !important;
}

@media (max-width: 576px) {
  .bee-upload-dialog.p-dialog {
    width: 95vw;
  }
}

@media (min-width: 576px) and (max-width: 650px) {
  .bee-upload-dialog.p-dialog {
    width: 85vw;
  }
}
