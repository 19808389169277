.o-login-form {
  margin-bottom: 5rem;
  width: 100%;
  //card
  .p-card {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    .p-card-body {
      padding: 1rem 2rem 1.5rem 2rem !important;

      //content
      .field:last-of-type {
        margin-bottom: 0px;
      }

      //Btns
      .login-btn {
        margin-bottom: 0.5rem;
        position: relative;

        button {
          width: 100%;
        }

        .p-progress-spinner {
          position: absolute;
          height: 26px;
          width: 26px;
          right: 10px;
          top: 5px;
        }
      }
      .forgotPw-btn {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: flex-end;

        button {
          font-size: 0.9rem;
          text-align: right;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
