.login-page {
  overflow-x: hidden;
  height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(0, 23, 57, 1) 0%,
      rgba(0, 23, 57, 0.5109768907563025) 41%,
      rgba(0, 23, 57, 0.2524334733893557) 100%
    ),
    url("../../Style/Images/beestate_titelfolie.jpg");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}
