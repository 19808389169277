.o-two-fac-form {
  margin-bottom: 3rem;
  width: 100%;
  //card
  .p-card {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    .p-card-body {
      padding: 1rem 2rem 1.5rem 2rem !important;
    }
  }
  //headline
  h2 {
    margin-top: 0px;
  }

  .code-input {
    margin-top: 1.5rem;
  }

  //Btns
  .auth-btn {
    margin-bottom: 0.5rem;
  }

  .p-button {
    width: 100%;
  }
}
