/* primary */

.bee-link-btn-primary {}

/* secondary */

.bee-link-btn-secondary {
    color: var(--secondary-dark-color) !important;
}

/* danger */

.bee-link-btn-danger {}

/* light */

.p-button.bee-link-btn-light {
    color: var(--surface-a) !important;
}

.bee-link-btn-light.p-button:not(a):not(.p-disabled):hover {
    color: var(--surface-a);
    background-color: var(--surface-700);
}

.bee-link-btn-light.p-button:not(a):not(.p-disabled):active {
    color: var(--surface-a);
    background-color: var(--surface-500);
}

.bee-link-btn-light.p-button:not(a):not(.p-disabled):focus {
    color: var(--surface-a);
    background-color: var(--surface-600);
}