.o-forgot-pw-form {
  margin-bottom: 3rem;
  width: 100%;
  //card
  .p-card {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    .p-card-body {
      padding: 1rem 2rem 1.5rem 2rem !important;
    }
  }

  //headline
  h2 {
    margin-top: 0px;
  }

  //Btns
  .send-email-btn {
    margin-top: 1.5rem;
    .p-button {
      width: 100%;
    }
  }
  .send-email-btn,
  .back-to-login-btn {
    margin-bottom: 0.5rem;
  }

  .back-to-login-btn {
    margin-left: -0.5rem;
    display: flex;
    justify-content: flex-start;

    button {
      font-size: 0.9rem;
      text-align: right;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      margin-bottom: 0.5rem;
    }
  }
}

.infotext {
  font-size: 0.9em;
  margin-bottom: 1rem;
}

.successtext {
  font-size: 0.85em;
  padding: 1rem 1.5rem 1rem 1rem;
  color: var(--valid-dark-color);
  background-color: #2a8e2a52;
  border-radius: 4px;
  position: relative;

  i {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: var(--valid-dark-color);
    font-size: 0.85em;
  }
}
