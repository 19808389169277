.bee-txt-input-container {
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    position: relative;
}

.bee-txt-input-container .p-inputtext {
    width: 100%
}

.bee-txt-input-label {
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    padding-left: 4px;
    padding-right: 6px;
    max-width: -webkit-calc(100% - 1.1rem);
    max-width: -moz-calc(100% - 1.1rem);
    max-width: calc(100% - 1.1rem);
    padding-left: 4px;
    padding-right: 6px;
}

.bee-txt-input-label-required {
    padding-right: 3px;
    padding-left: 4px;
}

.bee-txt-input-label .bee-txt-input-label-required-star {
    position: absolute;
    right: 0px;
    max-width: 100%;
    padding-right: inherit;
}

.bee-txt-input {
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bee-txt-input-valid {
    border-color: var(--valid-color);
    border-width: 2px;
}

.bee-txt-input-error {
    border-color: var(--error-color);
    border-width: 2px;
}

.bee-txt-input-neutral {}

.bee-txt-input-readOnly .p-inputtext {
    border-style: none;
    opacity: 1 !important;
}