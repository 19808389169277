.alpha-phases-page {
  .overview-header {
    display: flex;
  }
  .alpha-options-phasen-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-datatable-wrapper {
      border-radius: 4px;
      overflow: hidden;
    }
    table {
      .p-datatable-thead th,
      .p-datatable-tbody td,
      .p-datatable-tbody td div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      /* padding for first and last col */
      th:first-child,
      td:first-child {
        padding-left: 1rem !important;
      }
      th:last-child,
      td:last-child {
        padding-right: 1rem !important;
      }

      /* padding for header and body */
      .p-datatable-thead {
        vertical-align: baseline;
        tr th {
          padding-top: 1rem !important;
          padding-bottom: 1rem !important;
          background: var(--primary-color);
          color: var(--primary-color-text);
        }
      }

      .p-datatable-tbody td {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      .p-datatable-emptymessage td {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
      }

      tr {
        vertical-align: center;
      }

      /* content */

      .lv-table-liegenschaft {
        .lv-number {
          font-size: 0.8em;
        }
      }

      /* cols */

      .title-col {
        padding: 0 1rem !important;
        width: 80%;
      }

      .date-start-col {
        width: 14em;
      }
      .date-end-col {
        width: 14em;
      }
      .action-col {
        width: 7.25em;
        padding: 0 1rem !important;
        button {
          margin-right: 0.25em;
        }
      }
    }
  }
}
