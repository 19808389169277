.ep-lv-lvUpload-btn {
  display: flex;
  button {
    align-content: center;
  }
}

.ep-lv-lvDownload {
  button {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 0.5em;
  }

  .download-btn {
    button {
      padding-right: 0px;
      max-width: 100%;
      overflow: hidden;
      text-decoration: underline;
      text-underline-offset: 0.2em;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .delete-btn {
    button {
      padding-left: 0.5em;
      font-size: 0.9em;
      margin-top: 0.3em;
      width: unset;
      min-width: 1em;
    }
  }
  .col {
    padding-left: 0px;
    padding-right: 0px;
  }
}
