.invalid-usertype-page {
  .animationLocked {
    width: 25vw;
    margin: 2rem auto 4rem auto;
  }

  .bee-headline {
    margin-top: 3rem;
  }

  .lockout-btn {
    display: flex;
    align-items: center;
    margin-right: 35em;

    i {
      margin-right: 0.75rem;
    }
  }
}
