.bee-deletion-dialog.p-dialog {
  width: 40vw;
  max-width: 30rem;
}

.bee-deletion-dialog.p-dialog .p-confirm-dialog-message {
  margin-right: 1rem;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

/* primary */

.bee-deletion-dialog-primary.p-dialog .p-dialog-header,
.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-reject,
.bee-deletion-dialog-primary.p-dialog
  .p-dialog-header-icons
  .p-dialog-header-icon,
.bee-deletion-dialog-primary.p-dialog .p-dialog-content,
.bee-deletion-dialog-primary.p-dialog .p-dialog-footer {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-accept {
  background: var(--surface-a) !important;
  color: var(--error-color) !important;
  border: var(--error-color) 1px solid;
}

.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-accept:hover {
  background: var(--surface-300) !important;
  border-color: var(--error-color) !important;
}

.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-accept:active {
  background: var(--surface-400) !important;
  border-color: var(--error-color) !important;
}

.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-accept:focus {
  background: var(--surface-200) !important;
  border-color: var(--error-color) !important;
}

.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-reject:hover,
.bee-deletion-dialog-primary.p-dialog
  .p-dialog-header
  .p-dialog-header-icon:enabled:hover {
  background: var(--primary-light-color) !important;
  color: var(--primary-color-text) !important;
}

.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-reject:active,
.bee-deletion-dialog-primary.p-dialog .p-dialog-header-icon:active {
  background: var(--primary-light-color) !important;
  color: var(--primary-color-text) !important;
}

.bee-deletion-dialog-primary.p-dialog .p-confirm-dialog-reject:focus,
.bee-deletion-dialog-primary.p-dialog .p-dialog-header-icon:focus {
  background: var(--primary-light-color) !important;
  color: var(--primary-color-text) !important;
}

/* secondary */

.bee-deletion-dialog-secondary.p-dialog .p-dialog-header,
.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-reject,
.bee-deletion-dialog-secondary.p-dialog
  .p-dialog-header-icons
  .p-dialog-header-icon,
.bee-deletion-dialog-secondary.p-dialog .p-dialog-content,
.bee-deletion-dialog-secondary.p-dialog .p-dialog-footer {
  background: var(--secondary-color);
  color: var(--secondary-color-text);
}

.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-accept {
  background: var(--surface-a) !important;
  color: var(--error-color) !important;
  border: var(--error-color) 1px solid;
}

.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-accept:hover {
  background: var(--surface-300) !important;
  border-color: var(--error-color) !important;
}

.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-accept:active {
  background: var(--surface-400) !important;
  border-color: var(--error-color) !important;
}

.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-accept:focus {
  background: var(--surface-200) !important;
  border-color: var(--error-color) !important;
}

.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-reject:hover,
.bee-deletion-dialog-secondary.p-dialog .p-dialog-header-icon:hover {
  background: var(--secondary-light-color) !important;
  color: var(--secondary-color-text) !important;
}

.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-reject:active,
.bee-deletion-dialog-secondary.p-dialog .p-dialog-header-icon:active {
  background: var(--secondary-light-color) !important;
  color: var(--secondary-color-text) !important;
}

.bee-deletion-dialog-secondary.p-dialog .p-confirm-dialog-reject:focus,
.bee-deletion-dialog-secondary.p-dialog .p-dialog-header-icon:focus {
  background: var(--secondary-light-color) !important;
  color: var(--secondary-color-text) !important;
}

/* default */

.bee-deletion-dialog-default.p-dialog .p-confirm-dialog-accept:hover {
  background: var(--error-dark-color) !important;
}

.bee-deletion-dialog-default.p-dialog .p-confirm-dialog-reject:hover,
.bee-deletion-dialog-default.p-dialog .p-dialog-header-icon:hover {
  background: var(--primary-color-4) !important;
}

.bee-deletion-dialog-default.p-dialog .p-confirm-dialog-reject:active,
.bee-deletion-dialog-default.p-dialog .p-dialog-header-icon:active {
  background: var(--primary-light-active-color) !important;
}

.bee-deletion-dialog-default.p-dialog .p-confirm-dialog-reject:focus,
.bee-deletion-dialog-default.p-dialog .p-dialog-header-icon:focus {
  background: var(--primary-color-12) !important;
}

@media (max-width: 500px) {
  .bee-deletion-dialog.p-dialog {
    width: 80vw;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .bee-deletion-dialog.p-dialog {
    width: 60vw;
  }
}
