.alpha-user-page {
  .user-header {
    display: flex;
  }

  .alpha-user-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .sm-invisible {
      display: table-cell;
    }

    .p-datatable-wrapper {
      border-radius: 4px;
      overflow: hidden;
    }
    table {
      .p-datatable-thead th div,
      .p-datatable-tbody td,
      .p-datatable-tbody td div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      /* padding for first and last col */
      th:first-child,
      td:first-child {
        padding-left: 1rem !important;
      }
      th:last-child,
      td:last-child {
        padding-right: 1rem !important;
      }

      /* padding for header and body */
      .p-datatable-thead {
        vertical-align: baseline;
        tr th {
          padding-top: 1rem !important;
          padding-bottom: 1rem !important;
          background: var(--primary-color);
          color: var(--primary-color-text);
        }
      }

      .p-datatable-tbody td {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      .p-datatable-emptymessage td {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
      }

      tr {
        vertical-align: center;
      }
      /* content */
      .bee-checkbox-input {
        border-style: none;
      }
      .p-checkbox-box.p-highlight {
        border-color: var(--secondary-dark-color);
        background: var(--secondary-dark-color);
      }

      /* cols */

      .firstname-col {
        padding: 0 1rem !important;
        width: 20%;
      }

      .lastname-col {
        width: 20%;
      }
      .organisation-col {
        width: 20%;
      }

      .email-col {
        width: 45%;
      }
      .registration-col {
        width: 9em;
        text-align: center !important;
      }
      .readRight-col {
        width: 7.5em;
        text-align: center !important;
      }
      .writeRight-col {
        width: 8em;
        text-align: center !important;
      }

      .action-col {
        width: 4.5em;
        padding: 0 1rem !important;
        button {
          margin-right: 0.25em;
        }
      }
    }
    @media screen and (max-width: '900px') {
      .sm-invisible {
        display: none;
      }
    }
  }
}
