.dl-liegenschaft-prev.p-card {
  width: 100%;
  .p-card-body {
    padding: 0px;
    overflow: hidden;
  }
}

.dl-liegenschaft-prev.p-card .p-card-content {
  max-width: 100%;
  padding: 0px;
}

/* Cardview */

.dl-liegenschaft-prev-card.p-card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 8px 27px 3px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 4px;
  padding-bottom: 10px;
  margin: 0px;
  position: relative;
}

.dl-liegenschaft-prev-card-headline {
  padding: 1em 0.75rem 0.5em 1.5rem;
  background-color: var(--secondary-dark-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  h2 {
    color: var(--primary-color);
    font-weight: 500;
  }
}

.dl-liegenschaft-prev-card-headline div {
  font-size: 0.7em;
  color: var(--primary-color-text);
  font-weight: 800;
  line-height: 1.1em;
  height: 1.5em;
  width: calc(100% - 40px);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  display: block;
  word-break: break-all;
}

.dl-liegenschaft-prev-card-headline h2 {
  font-size: 1em;
  line-height: 1.2em;
  height: 2.5em;
  margin: 0em;
  margin-bottom: 0.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  width: calc(100% - 40px);
  padding: 0rem;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.dl-liegenschaft-prev-card-table {
  min-height: 5em;
  width: 100%;
  font-size: 0.9em;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  .card-table-sum-row {
    border-top: 2px solid var(--primary-border-color);
    border-collapse: collapse;
    td {
      border-top: 2px solid var(--primary-border-color);
      font-weight: 600;
      border-collapse: collapse;
      .bee-cur-input-container {
        margin-top: 5px;
        input {
          font-weight: 600;
        }
      }
    }
  }
  .card-table-price {
    text-align: right;
    padding-right: 0.75rem;
    .bee-cur-input-container {
      margin-top: 0px;
      input {
        border: none;
        font-size: 0.9em;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0.15rem;
        text-align: end;
        background: transparent !important;
      }
    }
  }
}

.dl-liegenschaft-prev-card-img {
  .bee-img {
    height: 180px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  .p-skeleton {
    overflow: hidden;
    padding-bottom: 10px;
    width: inherit !important;
    height: inherit !important;
    border-radius: 0px;
  }
}
.dl-liegenschaft-prev-card-content {
  position: relative;
  .card-notInMarketArea {
    position: absolute;
    top: 30%;
    left: -50%;
    padding: 0.75em;
    background: var(--error-dark-color);
    color: var(--primary-color-text);
    transform: rotate(-25deg);
    width: 200%;
    display: flex;
    justify-content: center;
    font-weight: 500;
  }
}

.dl-liegenschaft-prev-card-info {
  position: absolute;
  right: 0px;
  margin-top: 0.75em;
  margin-right: 0.75em;

  button.p-button {
    height: 1.75rem !important;
    width: 1.85rem;
  }
  .p-button-icon {
    font-size: 1rem;
  }
}

/* Hover deactivated */
.dl-liegenschaft-prev-card.p-card.dl-liegenschaft-prev-noHover {
  cursor: auto;
}

/* Hover activated */

.dl-liegenschaft-prev-card.p-card.dl-liegenschaft-prev-hover:hover {
  transform: scale(1.02) translateZ(0);
  -ms-transform: scale(1.02) translateZ(0);
  -webkit-transform: scale(1.02) translateZ(0);
  transition: all 0.07s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.dl-liegenschaft-prev-card.p-card.dl-liegenschaft-prev-hover:active {
  filter: brightness(0.9);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
