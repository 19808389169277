/* primary */

.bee-outlined-btn-primary {}

/* secondary */

.bee-outlined-btn-secondary {
  color: var(--secondary-dark-color) !important;
}

/* danger */

.bee-outlined-btn-danger {}

/* light */

.p-button.bee-outlined-btn-light {
  color: var(--surface-a) !important;
}

.bee-outlined-btn-light.p-button:not(a):not(.p-disabled):hover {
  color: var(--surface-a);
  background-color: var(--surface-700);
}

.bee-outlined-btn-light.p-button:not(a):not(.p-disabled):active {
  color: var(--surface-a);
  background-color: var(--surface-500);
}

.bee-outlined-btn-light.p-button:not(a):not(.p-disabled):focus {
  color: var(--surface-a);
  background-color: var(--surface-600);
}