.alpha-dl-epOverTime {
  /* tabview */

  .p-tabview {
    margin-top: 1.5em;
  }
  /* table */

  .alpha-dl-epOverTime-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-treetable-wrapper,
    .p-treetable-scrollable-wrapper {
      border-radius: 4px;
      overflow: hidden;
    }
    /* allg */
    .p-treetable-scrollable-header-box,
    .p-treetable-scrollable-footer-box {
      margin-right: 0px !important;
    }
    .p-treetable-thead th {
      white-space: nowrap;
    }
    .p-treetable-thead th,
    .p-treetable-tbody td {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    table {
      tr {
        position: relative;
      }
      /* padding for first and last col */
      th:first-child,
      td:first-child {
        padding-left: 1rem !important;
      }

      th:last-child,
      td:last-child {
        padding-right: 1rem !important;
      }

      /* padding for header and body */

      .p-treetable-tbody td {
        padding: 0.25rem 0.75rem !important;
      }

      .p-treetable-emptymessage td {
        padding: 0.5rem 0.75rem !important;
      }
    }

    /* head */
    .p-treetable-thead tr th {
      background: var(--primary-color);
      color: var(--primary-color-text);
    }

    /* titlecol */

    .ep-position-col {
      .priceInput-ep-code {
        font-size: 0.9em;
        color: var(--surface-500);
      }
    }
    tbody tr {
      .ep-position-col {
        white-space: nowrap;
        text-overflow: clip;
        .p-treetable-toggler,
        .priceInput-table-title {
          display: inline-block;
        }
      }
    }

    /* epcol */
    .phase-epPrice-col {
      .phase-period {
        font-size: 0.8em;
      }
      .alpha-dl-epOverTime-ep-value {
        display: inline-flex;
        align-items: center;
        input {
          text-align: right;
          background-color: transparent;
        }
      }
    }
  }
}
