.dl-uploadDocuments {
  margin-top: 4.5rem;
  h2 {
    text-align: left;
  }

  .add-doc-btn {
    text-align: left;
  }
  .error {
    margin-bottom: 0.75em;
    margin-top: -0.5em;
  }
  .uploadDocuments-descr {
    margin-bottom: 1.75em;
  }
}
.error {
  color: var(--error-dark-color);
}
