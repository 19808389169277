.alpha-property-detail {
  .property-detail-headline {
    display: flex;
    padding-bottom: 0px;
    align-items: center;

    h2 {
      margin: 0;
    }
    button.p-button {
      font-size: 1.5em;
      margin-left: 0.5em;
      width: auto;
      max-width: 2.25rem;
      min-width: 2.25rem;

      .p-button-icon {
        font-size: 1rem;
      }
    }
  }
  .alpha-property-detail {
    align-items: flex-start;
    .property-detail-info {
      padding-top: 0px;
    }
  }
  .property-detail-dl-selection {
    margin-bottom: -2em;
  }
}
