.alpha-lv-verwaltung {
  /* datatable */
  .lv-verwaltung-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-datatable-wrapper {
      border-radius: 4px;
      overflow: hidden;
    }

    .p-datatable-thead th,
    .p-datatable-tbody td,
    .p-datatable-tbody td div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    /* padding for first and last col */
    table th:first-child,
    table td:first-child {
      padding-left: 1rem !important;
    }
    table th:last-child {
      padding-right: 1rem !important;
    }

    /* padding for header and body */
    .p-datatable-thead {
      vertical-align: baseline;
      th {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
    }

    .p-datatable-tbody td {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .p-datatable-emptymessage td {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    tr {
      vertical-align: baseline;
    }

    /* content */

    .lv-table-liegenschaft {
      .lv-number {
        font-size: 0.8em;
      }
    }

    /* cols */

    .Liegenschaft-col {
      // padding: 0 1rem !important;
      width: 20%;
    }

    .Adresse-col {
      width: 20%;
    }

    .lv-phase {
      padding-left: 1em !important;
    }
  }

  /* dark */

  .lv-verwaltung-table table .p-datatable-thead tr th {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }
}
