.bee-flat-img-gallery-entry {
  height: 160px !important;
  width: 160px !important;
}
.bee-flat-img-gallery-entry .bee-gallery-img-add-button {
  width: 40%;
  height: 40%;
}
.bee-flat-img-gallery-entry
  .bee-gallery-img-add-button
  .p-button.p-button-icon-only.p-button-rounded {
  width: 60%;
  height: 60%;
}

.bee-flat-img-gallery-entry .bee-image-prev-copyright {
  display: none;
}

/* small screens */
@media (max-width: 1000px) {
  .bee-flat-img-gallery-entry {
    height: 130px !important;
    width: 130px !important;
    padding: 0.4rem;
  }
  .bee-flat-img-gallery-entry .bee-gallery-img-add-button {
    width: 35%;
    height: 35%;
  }
  .bee-flat-img-gallery-entry
    .bee-gallery-img-add-button
    .p-button.p-button-icon-only.p-button-rounded {
    width: 65%;
    height: 65%;
  }
  .bee-flat-img-gallery-entry
    .bee-gallery-img-close-button
    .p-button.p-button-icon-only.p-button-rounded {
    height: 2rem;
    width: 2rem;
  }
  .bee-flat-img-gallery-entry .bee-gallery-img-close-button .p-button-icon {
    font-size: 0.9rem;
  }
  .bee-flat-img-gallery-entry .bee-gallery-img-content .bee-img-title {
    font-size: 12px;
    height: 20px;
    bottom: -28px;
  }
}
