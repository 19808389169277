.alpha-analysis-cluster-lv {
  margin-top: 0.5rem;
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    filter: grayscale(100%);
  }
  tr.disabled-cluster td {
    & > div:not(.valid-background):not(.error-background),
    & > span {
      opacity: 0.5;
    }
  }
  tr.disabled-cluster td {
    & > .valid-background span,
    & > .error-background span {
      opacity: 0.5;
    }
  }

  .alpha-analysis-cluster-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-treetable-wrapper,
    .p-treetable-scrollable-wrapper {
      border-radius: 4px;
      overflow: hidden;

      /* allg */
      .p-treetable-thead th {
        white-space: nowrap;
      }
      .p-treetable-thead th,
      .p-treetable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-treetable-tbody td,
        .p-treetable-tfoot td {
          padding-top: 0.25rem !important;
          padding-bottom: 0.25rem !important;
          input {
            padding-top: 0em;
            padding-bottom: 0em;
          }
        }

        .p-treetable-emptymessage td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }

      /* head */
      .p-treetable-scrollable-header-box,
      .p-treetable-scrollable-footer-box {
        margin-right: 0px !important;
      }
      th {
        background: var(--primary-color) !important;
        color: var(--primary-color-text);
      }

      tr {
        border-bottom: 1px solid #e4e4e4;
        td {
          border-bottom: none;

          .p-inputtext {
            background: transparent;
          }
        }
      }

      .aAnalysisCluster-epPrice-col,
      .aAnalysisCluster-gpPrice-col,
      .aAnalysisCluster-diff-col,
      .aClusterLv-footer-ep,
      .aClusterLv-footer-gp,
      .aClusterLv-footer-diff {
        position: relative;
      }

      /* titlecol */

      tbody tr {
        position: relative;
        .clusterLv-position-col {
          white-space: nowrap;
          text-overflow: clip;
          .p-treetable-toggler,
          .analysisCluster-table-title {
            display: inline-block;
          }

          .analysisCluster-ep-code {
            font-size: 0.9em;
            color: var(--surface-500);
          }
        }
      }

      /* prices */
      .aAnalysisCluster-epPrice-col,
      .aAnalysisCluster-gpPrice-col,
      .aClusterLv-footer-ep,
      .aClusterLv-footer-gp {
        text-align: right;

        input {
          border: none;
          background: transparent;
          text-align: right;
        }
      }
      .aAnalysisCluster-epPrice-col,
      .aAnalysisCluster-gpPrice-col,
      .aAnalysisCluster-diff-col,
      .aClusterLv-footer-gp,
      .aClusterLv-footer-diff {
        div {
          display: flex;
          align-items: center;

          svg {
            width: 2rem;
            height: 1.3rem;
            margin: 0 0.25rem;
          }
        }
      }
    }
    /* type */
    .clusterLv-type-col.frozen-col {
      left: 30em;

      .analysisCluster-type {
        font-size: 0.9em;
        color: var(--surface-500);
      }
    }
    /* amount */
    .clusterLv-amount-col.frozen-col {
      left: 37em;

      .analysisCluster-unit {
        font-size: 0.9em;
        color: var(--surface-500);
      }
    }

    /* diff col */

    .clusterLv-diffPrice-col {
      border-right: 1px solid var(--surface-400);
    }

    svg {
      z-index: 1;
    }

    /* coloring */
    .error-color {
      input,
      .currency-euro-sign {
        color: var(--error-dark-color);
      }
    }

    .aAnalysisCluster-gpPrice-col:has(
        + .aAnalysisCluster-diff-col > .valid-background
      ):after,
    .aAnalysisCluster-epPrice-col:has(
        + .aAnalysisCluster-gpPrice-col
          + .aAnalysisCluster-diff-col
          > .valid-background
      ):after,
    .aClusterLv-footer-gp:has(
        + .aClusterLv-footer-diff > .valid-background
      ):after,
    .aClusterLv-footer-ep:has(
        + .aClusterLv-footer-gp + .aClusterLv-footer-diff > .valid-background
      ):after {
      content: '';
      background-color: var(--valid-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
    .aAnalysisCluster-gpPrice-col:has(
        + .aAnalysisCluster-diff-col > .warning-background
      ):after,
    .aAnalysisCluster-epPrice-col:has(
        + .aAnalysisCluster-gpPrice-col
          + .aAnalysisCluster-diff-col
          > .warning-background
      ):after,
    .aClusterLv-footer-gp:has(
        + .aClusterLv-footer-diff > .warning-background
      ):after,
    .aClusterLv-footer-ep:has(
        + .aClusterLv-footer-gp + .aClusterLv-footer-diff > .warning-background
      ):after {
      content: '';
      background-color: var(--warning-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }

    .aAnalysisCluster-gpPrice-col:has(
        + .aAnalysisCluster-diff-col > .error-background
      ):after,
    .aAnalysisCluster-epPrice-col:has(
        + .aAnalysisCluster-gpPrice-col
          + .aAnalysisCluster-diff-col
          > .error-background
      ):after,
    .aClusterLv-footer-gp:has(
        + .aClusterLv-footer-diff > .error-background
      ):after,
    .aClusterLv-footer-ep:has(
        + .aClusterLv-footer-gp + .aClusterLv-footer-diff > .error-background
      ):after {
      content: '';
      background-color: var(--error-dark-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
    .aAnalysisCluster-gpPrice-col:has(
        + .aAnalysisCluster-diff-col > .grey-background
      ):after,
    .aAnalysisCluster-epPrice-col:has(
        + .aAnalysisCluster-gpPrice-col
          + .aAnalysisCluster-diff-col
          > .grey-background
      ):after,
    .aClusterLv-footer-gp:has(
        + .aClusterLv-footer-diff > .grey-background
      ):after,
    .aClusterLv-footer-ep:has(
        + .aClusterLv-footer-gp + .aClusterLv-footer-diff > .grey-background
      ):after {
      content: '';
      background-color: var(--surface-600);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
  }

  @media screen and (max-width: 70em) {
    .sm-invisible {
      display: none;
    }

    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }
}
