.bee-switch {
  position: relative;
}

.bee-switch-input {
  display: block;
  position: relative;
  padding: 0.75rem 0.75rem;
  border-width: 1px;
  border-color: var(--primary-border-color);
  border-style: solid;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.bee-switch-input .p-inputswitch .p-inputswitch-slider:before {
  z-index: 10;
}

.bee-switch .p-checkbox {
  display: inline-flex;
}

.bee-switch-valid .bee-switch-input {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-switch-error .bee-switch-input {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-switch-readOnly .bee-switch-input {
  border-color: transparent !important;
  opacity: 1 !important;
}

.bee-switch-readOnly .p-inputswitch {
  opacity: 1 !important;
}

.bee-switch-label {
  display: block;
  position: absolute;
  pointer-events: none;
  top: -0.35rem !important;
  margin-left: 7.8px;
  pointer-events: none;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 12px;
  color: var(--text-color-secondary);
  transition-duration: 0.2s;
  background-color: var(--label-background-color);
  padding-left: 4px;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  padding-right: 6px;
  max-width: -webkit-calc(100% - 1rem);
  max-width: -moz-calc(100% - 1rem);
  max-width: calc(100% - 1rem);
}

.bee-switch-label-required {
  padding-left: 3px;
  padding-right: 4px;
}

.bee-switch-label .bee-switch-label-required-star {
  position: absolute;
  right: 0px;
  padding-left: inherit;
  padding-right: inherit;
  max-width: 100%;
}

.bee-switch-value {
  display: inline-block;
  padding-left: 7px;
  margin-bottom: -1px;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 48px);
  vertical-align: bottom;
}

.bee-switch-disabled .bee-switch-input {
  pointer-events: none;
  opacity: 0.38;
}
