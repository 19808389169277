.error-404 {
  .animation404 {
    width: 40vw;
    margin: 2rem auto 4rem auto;
  }

  .bee-headline {
    margin-top: 3rem;
  }

}
