.alpha-analysis-pricing {
  padding-top: 10px;
  padding-bottom: 10px;

  .alpha-analysis-factorEp-bl-table {
    margin-bottom: 2em;
  }
  .alpha-analysis-factorEp-prop-table {
    margin-bottom: 3em;
  }
  .p-treetable {
    .p-treetable-wrapper,
    .p-treetable-scrollable-wrapper {
      border-radius: 4px;
      overflow: hidden;
    }
    .p-treetable-scrollable-header-box {
      margin-right: 0px !important;
    }

    .p-treetable-thead th,
    .p-treetable-tbody td,
    .p-treetable-tbody td p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    /* padding for first and last col */
    table th:first-child,
    table td:first-child {
      padding-left: 1rem !important;
    }

    table th:last-child,
    table td:last-child {
      padding-right: 1rem !important;
    }

    /* padding for header and body */

    .p-treetable-tbody td {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    .p-treetable-emptymessage td {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    /* cols */
    .prop-factor-col,
    .prop-diff-col,
    .bl-factor-col,
    .bl-diff-col {
      position: relative;
      .bee-num-input-container {
        margin: 0px;
        input {
          padding: 0px !important;
          width: 2.8em;
          background: transparent;
        }
      }
    }

    /* dark */

    table .p-treetable-thead tr th {
      background: var(--primary-color);
      color: var(--primary-color-text);
    }

    /* coloring */
    .bl-factor-col:has(+ .bl-diff-col > .valid-background):after,
    .prop-factor-col:has(+ .prop-diff-col > .valid-background):after {
      content: '';
      background-color: var(--valid-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }

    .bl-factor-col:has(+ .bl-diff-col > .warning-background):after,
    .prop-factor-col:has(+ .prop-diff-col > .warning-background):after {
      content: '';
      background-color: var(--warning-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }

    .bl-factor-col:has(+ .bl-diff-col > .error-background):after,
    .prop-factor-col:has(+ .prop-diff-col > .error-background):after {
      content: '';
      background-color: var(--error-dark-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
    .bl-factor-col:has(+ .bl-diff-col > .grey-background):after,
    .prop-factor-col:has(+ .prop-diff-col > .grey-background):after {
      content: '';
      background-color: var(--surface-600);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
  }
}
