.dl-overview-analytics {
  margin-bottom: 2.5em;
  &.grid {
    align-items: center;
  }
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    filter: grayscale(100%);
  }
  .dl-overview-analytics-chart {
    padding-left: clamp(0px, 10%, 6rem);
    padding-right: clamp(0px, 10%, 6rem);
    margin-top: -2rem;
    margin-bottom: -2rem;
    .p-chart {
      max-width: 45rem;
    }
  }

  .dl-overview-analytics-table {
    display: flex;
    align-items: center;
    .leistungsart-sum-table {
      max-width: 30em;
      margin-left: clamp(0px, 5%, 2rem);

      .p-datatable {
        th {
          font-weight: 600;
          border-bottom: 3px solid var(--primary-border-color);
          &.table-sum-col {
            text-align: end;
            padding-right: 1.5rem;
          }
        }
        .p-datatable-tbody {
          > tr {
            > td {
              padding: 0px;

              &:first-child {
                padding-left: 0.75rem;
              }
              &:last-child {
                padding-right: 0.75rem;
              }
              &.table-sum-col {
                input {
                  padding-top: 0.5em;
                  padding-bottom: 0.5em;
                }
              }

              .table-child {
                font-size: 0.9em;
                input {
                  font-size: 0.9em;
                  padding-top: 0.25em;
                  padding-bottom: 0.25em;
                }
              }
              &.table-leistungsart-col {
                .table-child {
                  padding-left: 0.75em;
                }
              }
            }

            &:last-of-type {
              font-weight: 600;
              border-top: 3px solid var(--primary-border-color);
              border-bottom: none;
              td {
                border-bottom: none;
              }
              input {
                font-weight: 600;
              }
            }
          }
        }

        input {
          border: none;
          text-align: right;
        }
      }
    }
  }
}
