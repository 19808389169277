.bee-content-paragraph {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.bee-content-paragraph-xsmall {
    font-size: 0.7em;
}

.bee-content-paragraph-small {
    font-size: 0.8em;
}

.bee-content-paragraph-medium {
    font-size: 1em;
}

.bee-content-paragraph-large {
    font-size: 1.15em;
}

.bee-content-paragraph-xlarge {
    font-size: 1.4em;
}

.bee-content-paragraph-primary {
    color: var(--primary-color);
}

.bee-content-paragraph-secondary {
    color: var(--secondary-dark-color);
}