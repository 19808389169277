.alpha-property-lv {
  margin-top: 0.5rem;
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    filter: grayscale(100%);
  }
  tr.disabled-lv td:not(.alphaPropLv-diffPrice-col) {
    & > * {
      opacity: 0.5;
    }
  }
  .alpha-propertyLv-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-treetable-scrollable-wrapper {
      border-radius: 4px;
      overflow: hidden;

      /* allg */
      .p-treetable-thead th {
        white-space: nowrap;
      }
      .p-treetable-thead th,
      .p-treetable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-treetable-tbody td,
        .p-treetable-tfoot td {
          padding-top: 0.25rem !important;
          padding-bottom: 0.25rem !important;
          input {
            padding-top: 0em;
            padding-bottom: 0em;
          }
        }

        .p-treetable-emptymessage td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }

      /* head */
      .p-treetable-scrollable-header-box,
      .p-treetable-scrollable-footer-box {
        margin-right: 0px !important;
      }
      th {
        background: var(--primary-color) !important;
        color: var(--primary-color-text);
        .propertyLv-title-col {
          div {
            padding-right: 1rem;
          }
          .p-button-icon-only {
            .icon-closeAll,
            .icon-openAll {
              &::before {
                font-weight: 700;
              }
            }
          }
        }
      }

      tr {
        border-bottom: 1px solid #e4e4e4;
        td {
          border-bottom: none;

          .p-inputtext {
            background: transparent;
          }
          div {
            display: flex;
            align-items: center;
          }
        }
      }

      .alphaPropLv-epPrice-col,
      .alphaPropLv-gpPrice-col,
      .alphaPropLv-diffPrice-col,
      .aPropLv-footer-ep,
      .aPropLv-footer-gp,
      .aPropLv-footer-diff {
        position: relative;
      }
      .alphaPropLv-epPrice-col,
      .alphaPropLv-gpPrice-col,
      .aPropLv-footer-ep,
      .aPropLv-footer-gp {
        input {
          text-align: right;
        }
      }

      /* titlecol */

      tbody tr {
        position: relative;

        .propertyLv-title-col {
          white-space: nowrap;
          text-overflow: clip;
          .p-treetable-toggler,
          .propertyLv-title {
            display: inline-block;
          }
          .dl-property-lv-aks {
            display: block;
            font-size: 0.9em;
            color: var(--surface-600);
          }
          .dl-property-lv-epCode {
            font-size: 0.9em;
            color: var(--surface-500);
          }
        }
      }

      /* prices */
      .propertyLv-gp-col,
      .propertyLv-ep-col,
      .propertyLv-fakEp-col {
        text-align: right;
      }
      .propertyLv-ep-col,
      .propertyLv-gp-col,
      .propertyLv-fakEp-col {
        input {
          border: none;
          background: transparent;
          text-align: right;
        }
      }
    }
    /* type */
    .propertyLv-type-col.frozen-col {
      left: 30em;
      .dl-property-lv-type {
        font-size: 0.9em;
        color: var(--surface-500);
      }
    }
    /* amount */
    .propertyLv-amount-col.frozen-col {
      left: 36em;
      .dl-property-lv-unit {
        font-size: 0.9em;
        color: var(--surface-500);
      }
    }

    /* diff col */

    .alphaPropLv-diffPrice-col,
    .aPropLv-footer-diff {
      border-right: 1px solid var(--surface-400);

      svg {
        z-index: 1;
        width: 2rem;
        height: 1.3rem;
        margin: 0 0.25rem;
      }
    }

    .incompletePrice-warning {
      color: var(--warning-color);
      z-index: 1;
    }
    /* footer */
    .p-treetable-tfoot {
      border-top: 1px solid var(--surface-400);
    }

    /* coloring */

    .error-color {
      input,
      .currency-euro-sign {
        color: var(--error-dark-color);
      }
    }

    .alphaPropLv-gpPrice-col:has(
        + .alphaPropLv-diffPrice-col > .valid-background
      ):after,
    .alphaPropLv-epPrice-col:has(
        + .alphaPropLv-gpPrice-col
          + .alphaPropLv-diffPrice-col
          > .valid-background
      ):after,
    .aPropLv-footer-gp:has(+ .aPropLv-footer-diff > .valid-background):after,
    .aPropLv-footer-ep:has(
        + .aPropLv-footer-gp + .aPropLv-footer-diff > .valid-background
      ):after {
      content: '';
      background-color: var(--valid-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
    .alphaPropLv-gpPrice-col:has(
        + .alphaPropLv-diffPrice-col > .warning-background
      ):after,
    .alphaPropLv-epPrice-col:has(
        + .alphaPropLv-gpPrice-col
          + .alphaPropLv-diffPrice-col
          > .warning-background
      ):after,
    .aPropLv-footer-gp:has(+ .aPropLv-footer-diff > .warning-background):after,
    .aPropLv-footer-ep:has(
        + .aPropLv-footer-gp + .aPropLv-footer-diff > .warning-background
      ):after {
      content: '';
      background-color: var(--warning-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }

    .alphaPropLv-gpPrice-col:has(
        + .alphaPropLv-diffPrice-col > .error-background
      ):after,
    .alphaPropLv-epPrice-col:has(
        + .alphaPropLv-gpPrice-col
          + .alphaPropLv-diffPrice-col
          > .error-background
      ):after,
    .aPropLv-footer-gp:has(+ .aPropLv-footer-diff > .error-background):after,
    .aPropLv-footer-ep:has(
        + .aPropLv-footer-gp + .aPropLv-footer-diff > .error-background
      ):after {
      content: '';
      background-color: var(--error-dark-color);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
    .alphaPropLv-gpPrice-col:has(
        + .alphaPropLv-diffPrice-col > .grey-background
      ):after,
    .alphaPropLv-epPrice-col:has(
        + .alphaPropLv-gpPrice-col
          + .alphaPropLv-diffPrice-col
          > .grey-background
      ):after,
    .aPropLv-footer-gp:has(+ .aPropLv-footer-diff > .grey-background):after,
    .aPropLv-footer-ep:has(
        + .aPropLv-footer-gp + .aPropLv-footer-diff > .grey-background
      ):after {
      content: '';
      background-color: var(--surface-600);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.14;
      z-index: 0;
    }
  }

  @media screen and (max-width: 70em) {
    .sm-invisible {
      display: none;
    }

    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }
}
